import React from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import FormInput from "../FormInput";

export default function ShopAddressForm() {
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<header>
				<h2 className='text-uppercase'>Address information</h2>
			</header>
			<hr className='line-info' />
			<br />
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='number'>
						Number
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='number'
							name='number'
							placeholder='380'
							register={register}
							validations={{ required: true }}
						/>
						{errors.number && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='street'>
						Street Address
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='street'
							name='street'
							placeholder='South Main Street'
							register={register}
							validations={{ required: true }}
						/>
						{errors.street && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='city'>
						City
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='city'
							name='city'
							placeholder='Houston'
							register={register}
							validations={{ required: true }}
						/>
						{errors.city && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='state'>
						State
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='state'
							name='state'
							placeholder='Texas'
							register={register}
							validations={{ required: true }}
						/>
						{errors.state && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='post_code'>
						Post Code
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='post_code'
							name='post_code'
							placeholder='43318'
							register={register}
							validations={{ required: true }}
						/>
						{errors.post_code && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='country'>
						Country
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='country'
							name='country'
							placeholder='United States'
							register={register}
							validations={{ required: true }}
						/>
						{errors.country && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
}
