import VsstaLoop from "assets/video/edited_video_vssta.mp4";
import React from "react";

const videos = [VsstaLoop];

class VideoLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      currentUrlIdx: 0,
    };

    this.handleEnded = this.handleEnded.bind(this);
  }

  handleEnded(e) {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.setAttribute("loop", true);
    const nextUrlIdx = (this.state.currentUrlIdx + 1) % videos.length;
    this.setState({ currentUrlIdx: nextUrlIdx });
  }

  render() {
    return (
      <div>
        <video
          id="videoPlayer"
          // src={videos[this.state.currentUrlIdx]}
          src={videos[this.state.currentUrlIdx]}
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1",
          }}
          type="video/mp4"
          onEnded={this.handleEnded}
        />
      </div>
    );
  }
}
export default VideoLoader;
