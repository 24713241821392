import React from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import FormInput from "../FormInput";

export default function CompanyShopInfoForm() {
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<header>
				<h2 className='text-uppercase'>Company/Shop information</h2>
			</header>
			<hr className='line-info' />
			<br />
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='companyname'>
						Company Name
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='companyname'
							name='companyname'
							placeholder='Company Name...'
							register={register}
							validations={{ required: true }}
						/>
						{errors.companyname && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='shop_name'>
						Shop Name
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='shop_name'
							name='shop_name'
							placeholder='Mikes Collision Center'
							register={register}
							validations={{ required: true }}
						/>
						{errors.shop_name && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
}
