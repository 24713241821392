import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import FormInput from "components/Forms/FormInput";
import Select from "react-select";

import { reactSelectStyles } from "consts";

export default function ShopSpecificsForm() {
	const {
		register,
		setValue,
		getValues,
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<header>
				<h2 className='text-uppercase'>Shop Specifics</h2>
			</header>
			<hr className='line-info' />
			<br />
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='wifi_user'>
						Wifi User Name
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='wifi_user'
							name='wifi_user'
							placeholder='Wifi Username'
							register={register}
							validations={{ required: true }}
						/>
						{errors.wifi_user && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='wifi_pass'>
						Wifi Password
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='wifi_pass'
							name='wifi_pass'
							placeholder='Wifi Password'
							register={register}
							validations={{ required: true }}
						/>
						{errors.wifi_pass && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='scanner_serial'>
						Scanner Serial Number
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						{/* <p>{JSON.stringify(formData, null, 2)}</p> */}
						<FormInput
							type='text'
							id='scanner_serial'
							name='scanner_serial'
							placeholder='989770001***'
							register={register}
							validations={{ required: true, minLength: 12 }}
						/>
						{errors.scanner_serial && (
							<Alert color='danger'>
								{errors.scanner_serial.type === "required" &&
									"This field is required"}
								{errors.scanner_serial.type === "minLength" &&
									"Minimum length is 12"}
							</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='telephone'>
						Phone Number
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='telephone'
							name='telephone'
							placeholder='740-548-000'
							register={register}
							validations={{ required: true }}
						/>
						{errors.telephone && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='shop_email'>
						Shop Email
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='email'
							id='shop_email'
							name='shop_email'
							placeholder='charlie.bailey@example.com'
							register={register}
							validations={{ required: true }}
						/>
						{errors.shop_email && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels'>Management System</label>
				</Col>
				<Col className='align-self-center' md='4'>
					<FormGroup>
						<Controller
							control={control}
							name='managementSystem'
							defaultValue=''
							render={({ field }) => (
								<Select
									{...field}
									styles={reactSelectStyles}
									placeholder='Management System'
									options={[
										{ value: "None", label: "None" },
										{ value: "Rome", label: "Rome" },
										{ value: "CCC One", label: "CCC One" },
										{ value: "CC3", label: "CC3" },
										{ value: "Mitchell", label: "Mitchell" },
										{ value: "Nexysis", label: "Nexysis" },
									]}
								/>
							)}
							rules={{ required: true }}
						/>
						{errors.managementSystem && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
}
