import { useEffect } from "react";

import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar";
import { Container, Row } from "reactstrap";

export default function Privacy() {
	useEffect(() => {
		document.body.classList.add("account-settings");
		return function cleanup() {
			document.body.classList.remove("account-settings");
		};
	}, []);
	return (
		<>
			<div style={{ marginBottom: "200px" }}>
				<VsstaHomeNavbar />
			</div>
			<Container className='mb-5'>
				<Row>
					<p>
						At VSSTA, we are committed to protecting your privacy and ensuring
						the security of your personal information. This Privacy Policy
						outlines how we handle the mobile phone numbers and other personal
						information collected through our services, including SMS
						communications.
					</p>
				</Row>
				<Row>
					<hr className='line-success w-100' />
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>1. Information We Collect</strong>
					</p>
					<p>
						When you sign up for our services, you provide us with your mobile
						phone number as part of the registration process. This information
						is used solely for the purpose of delivering repair instructions,
						multimedia messages, and coordinating follow-up communications
						related to our remote support services.
					</p>
				</Row>
				<Row className='mt-3'>
					<p>
						<strong>2. How We Use Your Information</strong>
						<br />
						Your mobile phone number is used exclusively for the following
						purposes:
						<ul>
							<li>
								Sending text messages with repair instructions and related
								information.
							</li>
							<li>Sending multimedia messages (photos, diagrams) as needed.</li>
							<li>
								Coordinating phone calls and follow-up communications to support
								our services.
							</li>
						</ul>
					</p>
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>3. Sharing of Information</strong>
						<br />
						We are committed to maintaining the confidentiality of your mobile
						phone number. As such:
						<ul>
							<li>
								<strong>No Sharing with Third Parties:</strong> No mobile
								information will be shared with third parties/affiliates for
								marketing/promotional purposes. All other categories exclude
								text messaging originator opt-in data and consent; this
								information will not be shared with any third parties.
							</li>
							<li>
								<strong>Data Protection:</strong> We do not share your text
								messaging originator opt-in data or consent information with any
								third parties.
							</li>
						</ul>
					</p>
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>4. Data Security</strong>
					</p>
					<p>
						We implement appropriate technical and organizational measures to
						protect your mobile phone number and other personal information from
						unauthorized access, disclosure, alteration, or destruction.
					</p>
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>5. Your Rights</strong>
					</p>
					<p>
						You have the right to withdraw your consent for SMS communications
						at any time. If you wish to opt out of receiving text messages from
						us, please contact us directly, and we will promptly remove your
						phone number from our SMS distribution list.
					</p>
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>6. Changes to This Policy</strong>
					</p>
					<p>
						We may update this Privacy Policy from time to time to reflect
						changes in our practices or legal requirements. Any changes will be
						posted on our website with an updated effective date.
					</p>
				</Row>
				<Row className='mt-2'>
					<p>
						<strong>7. Contact Us</strong>
					</p>
				</Row>
				<Row>
					<p>
						If you have any questions or concerns about this Privacy Policy or
						our practices regarding your personal information, please contact us
						at:
						<br />
						<br />
						<strong>VSSTA</strong>
						<br />
						<a href='mailto:support@vssta.com'>support@vssta.com</a>
					</p>
				</Row>
				<Row>
					<hr className='line-success w-100' />
					<p>
						Thank you for entrusting VSSTA Vehicle Scanning Solutions with your
						information. We are dedicated to protecting your privacy and
						providing you with the best possible service.
					</p>
				</Row>
			</Container>
		</>
	);
}
