import { useState } from "react";

export function useMultistepForm(steps) {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	function next() {
		if (currentStepIndex <= steps.length - 1) {
			setCurrentStepIndex((prev) => prev + 1);
		}
	}

	function back() {
		if (currentStepIndex >= 0) {
			setCurrentStepIndex((prev) => prev - 1);
		}
	}

	function goTo(index) {
		if (currentStepIndex >= 0 && currentStepIndex <= steps.length - 1) {
			setCurrentStepIndex(index);
		}
	}

	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		goTo,
		next,
		back,
		steps,
	};
}
