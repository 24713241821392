import React from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import FormInput from "../FormInput";

export default function BillingInfoForm() {
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<header>
				<h2 className='text-uppercase'>Payables Information</h2>
			</header>
			<hr className='line-info' />
			<br />
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='contactname'>
						Contact Name
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='contactname'
							name='contactname'
							placeholder='Name...'
							register={register}
							validations={{ required: true }}
						/>
						{errors.contactname && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='contactphone'>
						Contact Phone
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='contactphone'
							name='contactphone'
							placeholder='740-548-000'
							register={register}
							validations={{ required: true }}
						/>
						{errors.contactphone && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='contactemail'>
						Email
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='contactemail'
							name='contactemail'
							placeholder='charlie.bailey@example.com'
							register={register}
							validations={{ required: true }}
						/>
						{errors.contactemail && (
							<Alert color='danger'>This field is required</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
}
