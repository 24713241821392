import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import axios from "utils/Axios";
import { Spinner, Col, Row } from "reactstrap";

export default function PayPal(vssta_data) {
  const paypal = useRef();
  const [orderPayPal, setOrderPayPal] = useState();
  const [message, setMessage] = useState();
  const [redirectState, setRedirectState] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "VSSTA ADAS Certification Program",
                amount: {
                  currency_code: "USD",
                  value: 400.0,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          setLoadingSpinner(true);
          const order = await actions.order.capture();
          setOrderPayPal(order);
        },
        onError: (err) => {
          setOrderPayPal(false);
          setMessage({
            text1: "Payment failed",
            text2: "Please contact with VSSTA support",
          });
          // ERROR WITH PAYMENT.
          console.log("ERROR!!!!!!!!!!");
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);

  if (orderPayPal) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", vssta_data.data.validatedData.file);
    bodyFormData.append("shop_name", vssta_data.data.validatedData.shop_name);
    bodyFormData.append("city", vssta_data.data.validatedData.city);
    bodyFormData.append("country", vssta_data.data.validatedData.country);
    bodyFormData.append("number", vssta_data.data.validatedData.number);
    bodyFormData.append("post_code", vssta_data.data.validatedData.post_code);
    bodyFormData.append("state", vssta_data.data.validatedData.state);
    bodyFormData.append("street", vssta_data.data.validatedData.street);
    bodyFormData.append("tech_email", vssta_data.data.validatedData.tech_email);
    bodyFormData.append(
      "tech_first_name",
      vssta_data.data.validatedData.tech_first_name
    );
    bodyFormData.append(
      "tech_last_name",
      vssta_data.data.validatedData.tech_last_name
    );
    bodyFormData.append("tech_phone", vssta_data.data.validatedData.tech_phone);
    bodyFormData.append("cert_type", vssta_data.data.validatedData.cert_type);
    bodyFormData.append("order_data", JSON.stringify(orderPayPal));

    axios
      .post("/activation/adas_cert_program", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoadingSpinner(false);
        setMessage({
          text1: "Your orders has been completed successfully",
          text2: "You will get email soon with more details",
        });
        setRedirectState(true);
      })
      .catch((error) => {
        setLoadingSpinner(false);
        setMessage({
          text1:
            "Your order been completed successfully however data upload failed",
          text2: "Please contact with VSSTA support",
        });
        setRedirectState(true);
      });
    setOrderPayPal(false);
  }

  return (
    <div>
      <div className="mx-auto">
        {loadingSpinner ? (
          <Row style={{ marginBottom: "10px" }}>
            <Col className="row d-flex justify-content-center flex-nowrap">
              <Spinner
                animation="border"
                color="success"
                style={{
                  margin: "10px",
                  textAlign: "center",
                  width: "4rem",
                  height: "4rem",
                }}
              />
            </Col>
          </Row>
        ) : null}
      </div>
      <div ref={paypal}></div>

      {redirectState ? (
        <Redirect to={{ pathname: "/success", state: { message: message } }} />
      ) : null}
    </div>
  );
}
