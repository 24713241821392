import { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Row, Col, FormGroup, Alert, Button } from "reactstrap";
import FormInput from "components/Forms/FormInput";
import Select from "react-select";
import { reactSelectStyles } from "consts";

export default function UserInfoForm() {
	const [userList, setUserList] = useState({
		values: [{ firstName: "", lastName: "", email: "", jobTitle: "Estimator" }],
	});

	const {
		register,
		setValue,
		control,
		formState: { errors },
	} = useFormContext();

	function addClick() {
		const newUser = {
			firstName: "",
			lastName: "",
			email: "",
			jobTitle: "Estimator",
		};
		setUserList((prevState) => {
			return { values: [...prevState.values, newUser] };
		});
		setValue("users", userList.values);
	}

	function removeClick(i) {
		let values = [...userList.values];
		values.splice(i, 1);
		setUserList({ values });
		setValue("users", userList.values);
	}

	function handleUserFirstNameChange(i, event) {
		let values = [...userList.values];
		values[i].firstName = event.target.value;
		setUserList({ values });
		setValue("users", userList.values);
	}

	function handleUserLastNameChange(i, event) {
		let values = [...userList.values];
		values[i].lastName = event.target.value;
		setUserList({ values });
		setValue("users", userList.values);
	}

	function handleUserEmailChange(i, event) {
		let values = [...userList.values];
		values[i].email = event.target.value;
		setUserList({ values });
		setValue("users", userList.values);
	}

	function handleUserJobTitleChange(i, selectedOption) {
		let values = [...userList.values];
		values[i].jobTitle = selectedOption.value;
		setUserList({ values });
		setValue("users", userList.values);
	}

	function createUserTable() {
		return userList.values.map((currentUser, i) => (
			<div key={i}>
				{console.log(currentUser.firstName)}
				<h4>User number {i + 1}</h4>
				<div>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`first_name_${i}`}>
								First Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`first_name_${i}`}
									name={`first_name_${i}`}
									placeholder='Charlie'
									value={currentUser.firstName}
									onChange={(event) => handleUserFirstNameChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`first_name_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`last_name_${i}`}>
								Last Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`last_name_${i}`}
									name={`last_name_${i}`}
									placeholder='Bailey'
									value={currentUser.lastName}
									onChange={(event) => handleUserLastNameChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`last_name_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`email_${i}`}>
								Email
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`email_${i}`}
									name={`email_${i}`}
									placeholder='charlie.bailey@example.com'
									value={currentUser.email}
									onChange={(event) => handleUserEmailChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`email_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`job_title_${i}`}>
								Job Title
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<Controller
									control={control}
									name={`jobTitle_${i}`}
									defaultValue='Estimator'
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											{...field}
											styles={reactSelectStyles}
											placeholder='Job Title'
											options={[
												{ value: "Estimator", label: "Estimator" },
												{ value: "Shop Manager", label: "Shop Manager" },
												{
													value: "Shop Regional Manager",
													label: "Shop Regional Manager",
												},
												{ value: "Company Owner", label: "Company Owner" },
												{ value: "Technician", label: "Technician" },
											]}
											value={{
												value: currentUser.jobTitle,
												label: currentUser.jobTitle,
											}}
											onChange={(selectedOption) => {
												field.onChange(selectedOption);
												handleUserJobTitleChange(i, selectedOption);
											}}
										/>
									)}
								/>
								{errors[`job_title_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Button color='warning' type='button' onClick={removeClick.bind(i)}>
						Remove User
					</Button>
				</div>
				<br />
			</div>
		));
	}

	return (
		<>
			<header>
				<h2 className='text-uppercase'>User Information</h2>
			</header>
			<hr className='line-info' />
			<br></br>
			{createUserTable()}
			<Button color='info' type='button' value='Add user' onClick={addClick}>
				Add user
			</Button>
		</>
	);
}
