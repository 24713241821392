import Axios from "axios";
const { REACT_APP_API_URL } = process.env;
// Activate endpoint: /activation/activate_user

// Default API
const axios = Axios.create({
  baseURL: REACT_APP_API_URL,
});

export default axios;
